import React, { useState, useCallback } from 'react'
import {
    View,
    Pressable,
    Text,
    Image,
    ViewStyle,
    TextStyle,
    StyleProp,
    PressableProps,
    Platform,
} from 'react-native'
import styles from './styles'
import COLORS from '../../utils/colors'
import STRINGS from '../../utils/strings'

interface BreadcrumbItem {
    id: string
    name: string
}

export interface PressableItemsProps extends Omit<PressableProps, 'onPress'> {
    onPress: (index: number) => any
}

export interface BreadcrumbsProps {
    /** Data for Breadcrumbs */
    testID?: string
    pageData: BreadcrumbItem[]
    icon?: React.ReactNode
    textStyle?: StyleProp<TextStyle>
    activeTextStyle?: StyleProp<TextStyle>
    hoverViewStyle?: StyleProp<ViewStyle>
    /** Container content pass to the FlatList */
    containerStyle?: StyleProp<ViewStyle>
    activePageIndex?: number
    pressableProps?: PressableItemsProps
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    testID,
    pageData,
    icon,
    textStyle,
    activeTextStyle,
    hoverViewStyle,
    containerStyle,
    activePageIndex,
    pressableProps,
}) => {
    const [selectedItem, setSelectedItem] = useState<number | null>(
        activePageIndex || null,
    )
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

    const onIndexChangeVal = useCallback(
        (index: number): void => {
            setSelectedItem(index)
            pressableProps?.onPress(index)
        },
        [pressableProps],
    )

    const onIndexHoverVal = useCallback((index: number | null): void => {
        setHoveredIndex(index)
    }, [])

    const renderIcon = useCallback(
        (index: number): React.ReactNode => {
            if (index > 0) {
                return icon ? (
                    icon
                ) : (
                    <Image
                        resizeMode="contain"
                        style={styles.icon}
                        source={{
                            uri: 'https://i.imgur.com/JuKMaKC.png',
                        }}
                    />
                )
            }
            return null
        },
        [icon],
    )

    const renderText = useCallback(
        (item: BreadcrumbItem, isSelected: boolean): React.ReactNode => (
            <Text
                style={[
                    isSelected ? styles.boldText : styles.breadcrumbText,
                    isSelected ? activeTextStyle : textStyle,
                ]}
            >
                {item.name}
            </Text>
        ),
        [activeTextStyle, textStyle],
    )

    const renderHoverView = useCallback(
        (
            isHovered: boolean,
            item: BreadcrumbItem,
            isSelected: boolean,
        ): React.ReactNode => (
            <View
                style={[
                    styles.hoverView,
                    {
                        backgroundColor: isHovered
                            ? COLORS.COLOR_BTNGRAY
                            : 'transparent',
                    },
                    isHovered ? hoverViewStyle : {},
                ]}
            >
                {renderText(item, isSelected)}
            </View>
        ),
        [hoverViewStyle, renderText],
    )

    const renderItem = useCallback(
        ({
            item,
            index,
        }: {
            item: BreadcrumbItem
            index: number
        }): React.ReactElement => {
            const isSelected = selectedItem === index
            const isHovered = hoveredIndex === index

            const pressablePropsUpdated = {
                ...pressableProps,
                ...Platform.select({
                    web: {
                        onMouseEnter: () => onIndexHoverVal(index),
                        onMouseLeave: () => onIndexHoverVal(null),
                    },
                }),
            }

            return (
                <View
                    testID={`${testID}-${index}`}
                    style={styles.item}
                    key={index.toString()}
                >
                    {renderIcon(index)}
                    <Pressable
                        testID={STRINGS.BTN_TESTID}
                        {...pressablePropsUpdated}
                        onPress={() => onIndexChangeVal(index)}
                    >
                        {renderHoverView(isHovered, item, isSelected)}
                    </Pressable>
                </View>
            )
        },
        [
            selectedItem,
            hoveredIndex,
            pressableProps,
            onIndexHoverVal,
            renderIcon,
            renderHoverView,
            onIndexChangeVal,
            testID,
        ],
    )

    return (
        <View style={[styles.contentContainer, containerStyle]} testID={testID}>
            {pageData.map((item, index) => renderItem({ item, index }))}
        </View>
    )
}

export default Breadcrumbs
