Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getCategoriesEndPoint = "bx_block_categories/categories";
exports.getApiMethod = "GET";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.apiContentType = "application/json";
exports.getStoresApiEndPoint = "bx_block_storelocator/store_locators/businesses_by_city?city=";
exports.getGeofenceAPiEndPoint = "bx_block_geofence4/geofences";
exports.searchingApiEndPoint = "bx_block_advanced_search/search/search_recommendation?search=";
exports.getCityBoundary = "https://nominatim.openstreetmap.org/search?";
exports.exampleAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Maps";
exports.labelBodyText = "Maps Body";

exports.btnExampleTitle = "CLICK ME";
exports.customMapStyle = [
  {
    featureType: "poi.sports_complex",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#b2a4cf"
      },
      {
        weight: 1.5
      }
    ]
  },
  {
    featureType: "landscape.natural",
    stylers: [
      {
        color: "#fef8c5"
      }
    ]
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#8861a9"
      }
    ]
  },
  {
    featureType: "road.highway",
    stylers: [
      {
        color: "#8861a9"
      }
    ]
  },
  {
    featureType: "poi.park",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#8861a9"
      }
    ]
  },
  {
    featureType: "poi.government",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#8861a9"
      }
    ]
  },
  {
    featureType: "poi.attraction",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi.medical",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi.place_of_worship",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi.school",
    stylers: [
      {
        visibility: "off"
      }
    ]
  }
]

// Customizable Area End