import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef } from "react";
import { ScrollView } from "react-native";
import { ValueType } from "react-select";
const config = require("../../../framework/src/config");
type BusinessQuestion = {
  id: number;
  question: string;
  question_type: "Dropdown" | "Single line",
  options?: Array<{
    id: number;
    name: string;
  }>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  subscription_type: string;
  max_upload_photos: number;
  loading: boolean;
  alertVisibility: boolean;
  enablePasswordField: boolean;
  alertMessage: string;
  account_id: number | null;
  fullName: string;
  email: string;
  street: string;
  city: ValueType<{ label: string; value: string; }, false>;
  state: ValueType<{ label: string; value: string; }, false>;
  country: ValueType<{ label: string; value: string; }, false>;
  countriesName: Array<{ label: string, value: string }>;
  statesName: Array<{ label: string, value: string }>;
  citiesName: Array<{ label: string, value: string }>;
  zipcode: string;
  bio: string;
  facebook: string;
  instagram: string;
  operatingHours: {
    [key: string]: {
      open: boolean;
      start_hour: string | null,
      end_hour: string | null
    }
  };
  linkWebsite: string;
  password: string;
  uploadedPictures: string[];
  uploadedFiles: Blob[];
  phone_number: string;
  businessQuestionsList: BusinessQuestion[];
  businessAnswersList: Array<{
    question_id: number;
    answer: string;
  }>;
  businessAnswersDropdownList: Array<{
    question_id: number;
    answer: ValueType<{ label: string; value: string; }, false>;
  }>;
  business_answers: Array<{
    question_id: number;
    answer: string;
  }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UpdateBusinessInformationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  updateBusinessInfoScrollRef = createRef<ScrollView>();
  inputProfilePictureRef: React.RefObject<HTMLInputElement>;
  token: string = "";
  updateBusinessInformationCallId: string = "";
  getBusinessQuestionApiCallId: string = "";
  updateBusinessAnswersApiCallId: string = "";
  countriesNameApiCallId: string = "";
  statesNameApiCallId: string = "";
  citiesNameApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      subscription_type: "Basic",
      max_upload_photos: 0,
      enablePasswordField: true,
      loading: false,
      alertVisibility: false,
      alertMessage: "",
      account_id: null,
      fullName: "",
      email: "",
      street: "",
      city:{ label: "", value: ""},
      state: { label: "", value: ""},
      country: { label: "", value: ""},
      countriesName: [],
      statesName: [],
      citiesName: [],
      zipcode: "",
      operatingHours: {
        sunday: {
          open: false,
          start_hour: null, 
          end_hour: null
        },
        monday: {
          open: false,
          start_hour: null, 
          end_hour: null
        },
        tuesday: {
          open: false,
          start_hour: null, 
          end_hour: null
        },
        wednesday: {
          open: false,
          start_hour: null, 
          end_hour: null
        },
        thursday: {
          open: false,
          start_hour: null, 
          end_hour: null
        },
        friday: {
          open: false,
          start_hour: null, 
          end_hour: null
        },
        saturday: {
          open: false,
          start_hour: null, 
          end_hour: null
        },
      },
      linkWebsite: "",
      phone_number: "",
      password: "",
      uploadedPictures: [],
      uploadedFiles: [],
      bio: "",
      facebook: "",
      instagram: "",
      businessQuestionsList: [],
      businessAnswersList: [],
      businessAnswersDropdownList: [],
      business_answers: []
      // Customizable Area End
    };
    // Customizable Area Start
    this.inputProfilePictureRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getBusinessQuestions();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const isSessionResponseMessage = getName(MessageEnum.SessionResponseMessage) === message.id;
    const isRestAPIResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;

    this.handleNavigationPayloadMessage(message);

    if (isSessionResponseMessage) {
      this.handleSessionResponseUpdateBusinessInformation(message);
    }

    if (isRestAPIResponseMessage) {
      this.handleAPIResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  private handleSessionResponseUpdateBusinessInformation(message: Message) {
    const sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));
    if (!sessionToken || sessionToken === "null") {
      this.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
    } else {
      this.token = sessionToken
    }

    const testToken = window.localStorage.getItem("testToken");

    if (testToken) {
      this.token = testToken;
    }

    runEngine.debugLog("TOKEN1", this.token);
  }

  private handleAPIResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (responseJson && !responseJson.errors) {
      if (apiRequestCallId === this.updateBusinessInformationCallId) {
        this.navigateToProfile();
      } else if (apiRequestCallId === this.getBusinessQuestionApiCallId) {
        const businessQuestions = responseJson as Array<BusinessQuestion>;
        const sortedBusinessQuestions = [...businessQuestions].sort((a, b) => a.id - b.id);
  
        this.setState({
          businessQuestionsList: sortedBusinessQuestions,
          businessAnswersDropdownList: this.state.business_answers
            .filter((answer: { question_id: number, answer: string | number }) => 
              sortedBusinessQuestions.find(question => question.id === answer.question_id && question.question_type === "Dropdown")
            ).map(answer => {
              return {
                question_id: answer.question_id,
                answer: {
                  value: answer.answer,
                  label: sortedBusinessQuestions.find(question => question.id === answer.question_id)?.options?.find(option => option.id === Number(answer.answer))?.name || ""
                }
              }
            }),
          businessAnswersList: this.state.business_answers.filter((answer: { question_id: number, answer: string | number }) => sortedBusinessQuestions.find(question => question.id === answer.question_id && question.question_type === "Single line")),
        });
      } else if (apiRequestCallId === this.countriesNameApiCallId) {
        this.handleCountriesNameApiResponse(responseJson);
      } else if (apiRequestCallId === this.statesNameApiCallId) {
        this.handleStatesNameApiResponse(responseJson);
      } else if (apiRequestCallId === this.citiesNameApiCallId) {
        this.handleCitiesNameApiResponse(responseJson);
      }
    } else if (responseJson.errors && 
      (
        apiRequestCallId === this.updateBusinessInformationCallId || 
        apiRequestCallId === this.getBusinessQuestionApiCallId ||
        apiRequestCallId === this.countriesNameApiCallId || 
        apiRequestCallId === this.statesNameApiCallId || 
        apiRequestCallId === this.citiesNameApiCallId
      )
    ) {
      this.showAlert(
        "Error",
        responseJson.errors[0] || "Something went wrong!"
      )
    }
  }

  private handleCountriesNameApiResponse(responseJson: any) {
    this.setState({
      countriesName: Object.entries<string>(responseJson.countries).map(([key, value]) => ({
        label: value,
        value: value
      }))
    })
  }

  private handleStatesNameApiResponse(responseJson: any) {
    this.setState({
      statesName: responseJson.states.map((state: string) => {
        return {
          label: state,
          value: state
        }
      })
    })
  }

  private handleCitiesNameApiResponse(responseJson: any) {
    this.setState({
      citiesName: responseJson.cities.map((city: string) => {
        return {
          label: city,
          value: city
        }
      })
    })
  }

  private handleNavigationPayloadMessage(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const { account_id, street, city, state, country, email, fullName, linkWebsite, phone_number, operatingHours, zipCode, bio, facebook, instagram, subscription_type, max_upload_photos, images, business_answers } = message.getData(getName(MessageEnum.NavigationBusinessInformationMessage));

      this.setState({
        account_id,
        street,
        city: { label: city, value: city },
        state: { label: state, value: state },
        country: { label: country, value: country },
        email,
        fullName,
        linkWebsite,
        operatingHours,
        zipcode: zipCode,
        bio,
        facebook,
        instagram,
        subscription_type,
        uploadedPictures: images.map((image: string) => `${config.baseURL}${image}`),
        max_upload_photos,
        phone_number,
        business_answers
      }, () => {
        this.getCountriesName();
        this.getStatesName(country);
        this.getCitiesName(state);
      }); 
      
      this.fetchImagesAsBlob(images);
    }
  }

  async fetchImagesAsBlob(images: string[]) {
    for (const image of images) {
      const response = await fetch(config.baseURL + image)
      const blob = await response.blob();
      this.setState({
        uploadedFiles: [...this.state.uploadedFiles, blob]
      })
    }
  }

  handleUpdateDropDownBusinessAnwser = (question_id: number, answer: ValueType<{ label: string; value: string; }, false>) => {
    this.setState({
      businessAnswersDropdownList: this.state.businessAnswersDropdownList.find(answer => answer.question_id === question_id) ? [
        ...this.state.businessAnswersDropdownList.filter(answer => answer.question_id !== question_id),
        {
          question_id,
          answer: answer
        }
      ] : [
        ...this.state.businessAnswersDropdownList,
        {
          question_id,
          answer: answer
        }
      ]
    })
  }

  handleUpdateBusinessAnwser = (question_id: number, text: string) => {
    this.setState({
      businessAnswersList: this.state.businessAnswersList.find(answer => answer.question_id === question_id) ? [
        ...this.state.businessAnswersList.filter(answer => answer.question_id !== question_id),
        {
          question_id,
          answer: text
        }
      ] : [
        ...this.state.businessAnswersList,
        {
          question_id,
          answer: text
        }
      ]
    })
  }

  showAlert(alertType: string, alertMessage: string) {
    if (this.updateBusinessInfoScrollRef.current) {
      this.updateBusinessInfoScrollRef.current.scrollTo({
        y: 0,
        animated: true
      })
    }

    this.setState({
      alertVisibility: true,
      alertMessage: alertMessage
    })
  }

  handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const MAX_SIZE_MB = 5 * 1024 * 1024;
      const MAX_FILES = this.state.max_upload_photos;
      const currentPictures = this.state.uploadedPictures.length;

      const validFiles = Array.from(files).filter(file => file.size <= MAX_SIZE_MB);

      if (validFiles.length === 0) {
        this.showAlert("Error", "Each file must be no larger than 5MB.");
        return;
      }

      const remainingFiles = MAX_FILES - currentPictures;

      const filesToUpload = validFiles.slice(0, remainingFiles);

      if (filesToUpload.length < validFiles.length) {
        this.showAlert("Error", `You can only upload ${MAX_FILES} ${MAX_FILES <= 1 ? "image" : "images"} for this subscription plan.`);
      } else {
        this.setState({
          alertVisibility: false,
          alertMessage: "",
        })
      }

      const picutres = filesToUpload.map(file => URL.createObjectURL(file))

      this.setState({
        uploadedPictures: [...this.state.uploadedPictures, ...picutres],
        uploadedFiles: [...this.state.uploadedFiles, ...filesToUpload.map(file => new Blob([file], { type: file.type }))],
      })
    }
  }

  handleRemovePicture = (index: number) => {
    const uploadedPicturesCopy = [...this.state.uploadedPictures];
    uploadedPicturesCopy.splice(index, 1);

    const uploadedFilesCopy = [...this.state.uploadedFiles];
    uploadedFilesCopy.splice(index, 1);

    this.setState({
      uploadedPictures: uploadedPicturesCopy,
      uploadedFiles: uploadedFilesCopy
    })
  }

  handleOpenSelectProfilePicture = () => {
    if (this.inputProfilePictureRef) {
      this.inputProfilePictureRef.current?.click();
    }
  }

  btnGoBack = {
    onPress: () => this.props.navigation.goBack()
  }

  txtInputNameProps = {
    onChangeText: (text: string) => {
      this.setState({ fullName: text });

      //@ts-ignore
      this.txtInputNameProps.value = text;
    },
  };

  txtInputEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputStreetProps = {
    onChangeText: (text: string) => {
      this.setState({ street: text });

      //@ts-ignore
      this.txtInputStreetProps.value = text;
    },
  };

  txtInputCityProps = {
    onChange: (value: ValueType<{ label: string; value: string; }, false>) => {
      this.setState({ city: value });

      //@ts-ignore
      this.txtInputCityProps.value = value;
    },
  };

  txtInputStateProps = {
    onChange: (value: ValueType<{ label: string; value: string; }, false>) => {
      this.setState({ state: value, city: { label: "", value: "" } });
      if (value?.label !== "") {
        this.getCitiesName(value!.label);
      }

      //@ts-ignore
      this.txtInputStateProps.value = value;
      this.txtInputCityProps.onChange({ label: "", value: "" });
    },
  };

  txtInputCountryProps = {
    onChange: (value: ValueType<{ label: string; value: string; }, false>) => {
      this.setState({ country: value, city: { label: "", value: "" }, state: { label: "", value: "" } });
      this.getStatesName(value!.label);

      //@ts-ignore
      this.txtInputCountryProps.value = value;
      this.txtInputStateProps.onChange({ label: "", value: "" });
      this.txtInputCityProps.onChange({ label: "", value: "" });
    },
  };

  txtInputZipcodeProps = {
    onChangeText: (text: string) => {
      this.setState({ zipcode: text });

      //@ts-ignore
      this.txtInputZipcodeProps.value = text;
    },
  };

  txtInputBioProps = {
    onChangeText: (text: string) => {
      this.setState({ bio: text });

      //@ts-ignore
      this.txtInputBioProps.value = text;
    },
  };

  handleUpadateOperatingHoursOpenClose = (value: boolean, day: string) => {
    this.setState({
      operatingHours: {
        ...this.state.operatingHours,
        [day.toLowerCase()]: {
          ...this.state.operatingHours[day.toLowerCase()],
          open: value,
        }
      }
    })
  }

  handleUpdateOperatingHoursStartHour = (value: string, day: string) => {
    this.setState({
      operatingHours: {
        ...this.state.operatingHours,
        [day.toLowerCase()]: {
          ...this.state.operatingHours[day.toLowerCase()],
          start_hour: value,
        }
      }
    })
  }

  handleUpdateOperatingHoursEndHour = (value: string, day: string) => {
    this.setState({
      operatingHours: {
        ...this.state.operatingHours,
        [day.toLowerCase()]: {
          ...this.state.operatingHours[day.toLowerCase()],
          end_hour: value,
        }
      }
    })
  }

  formatHour = (date: Date | null) => {
    if (!date) return "null"
    const hour = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hour}:${minutes}:${seconds}`
  }

  formatHourValueToSend = (date: string | null) => {
    if (date === null || date === "null") return "null";

    if (date.includes("AM") || date.includes("PM")) {
      return date;
    }

    let [hour, minutes] = date.split(":").map(Number);

    const period = hour >= 12 ? "PM" : "AM";

    hour = hour % 12 || 12;
    const hourFormatted = String(hour).padStart(2, "0");

    return `${hourFormatted}:${String(minutes).padStart(2, "0")} ${period}`;
  }

  formatHourToDate = (time: string | null) => {
    if (time === "null" || time === null) return null;

    const [timePart, modifier] = time.split(" ");

    let [hours, minutes] = timePart.split(":").map(Number);


    if (modifier?.toLowerCase() === 'pm' && hours !== 12) {
      hours += 12;
    } else if (modifier?.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }

    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, 0);
  }

  txtInputLinkWebsiteProps = {
    onChangeText: (text: string) => {
      this.setState({ linkWebsite: text });

      //@ts-ignore
      this.txtInputLinkWebsiteProps.value = text;
    },
  };

  txtInputPhoneProps = {
    onChangeText: (text: string) => {
      this.setState({ phone_number: text });

      //@ts-ignore
      this.txtInputPhoneProps.value = text;
    },
  };

  txtInputFacebookProps = {
    onChangeText: (text: string) => {
      this.setState({ facebook: text });

      //@ts-ignore
      this.txtInputFacebookProps.value = text;
    },
  };

  txtInputInstagramProps = {
    onChangeText: (text: string) => {
      this.setState({ instagram: text });

      //@ts-ignore
      this.txtInputInstagramProps.value = text;
    },
  };

  btnSaveChangesProps = {
    onPress: () => {
      if (this.validateSaveChanges()) {
        Promise.all([
          this.updateBusinessAnswers(),
          this.updateBusinessInformation()
        ])
      }
    }
  }

  navigateToProfile = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationUserProfileMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getCountriesName() {
    const headers = {
      "Content-Type": configJSON.searchApiContentType
    };

    const getCountryNameMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countriesNameApiCallId = getCountryNameMsg.messageId;

    getCountryNameMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryNameAPiEndPoint
    );

    getCountryNameMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getCountryNameMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getCountryNameMsg.id, getCountryNameMsg);
  }

  getStatesName(country: string) {
    const headers = {
      "Content-Type": configJSON.searchApiContentType
    };

    const getStateNameMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.statesNameApiCallId = getStateNameMsg.messageId;

    getStateNameMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateNameAPiEndPoint + country
    );

    getStateNameMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getStateNameMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getStateNameMsg.id, getStateNameMsg);
  }

  getCitiesName(state: string) {
    const headers = {
      "Content-Type": configJSON.searchApiContentType
    };

    const getCitiesNameMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.citiesNameApiCallId = getCitiesNameMsg.messageId;

    getCitiesNameMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCityNameAPiEndPoint + `?country=${this.state.country?.value}&state=${state}`
    );

    getCitiesNameMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getCitiesNameMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getCitiesNameMsg.id, getCitiesNameMsg);
  }

  validateSaveChanges = () => {
    if (this.state.fullName.trim().length === 0) {
      this.showAlert("Error", "Name is mandatory.");
      return false;
    }

    if (this.state.email.trim().length === 0) {
      this.showAlert("Error", "Email is mandatory.");
      return false;
    }

    if (this.state.street.trim().length === 0) {
      this.showAlert("Error", "Street is mandatory.");
      return false;
    }

    if (this.state.city?.value.trim().length === 0) {
      this.showAlert("Error", "City is mandatory.");
      return false;
    }

    if (this.state.state?.value.trim().length === 0) {
      this.showAlert("Error", "State is mandatory.");
      return false;
    }

    if (this.state.country?.value.trim().length === 0) {
      this.showAlert("Error", "Country is mandatory.");
      return false;
    }

    if (this.state.businessQuestionsList.length > this.state.businessAnswersList.length + this.state.businessAnswersDropdownList.length || !!this.state.businessAnswersList.find(answer =>  answer.answer.length === 0)) {
      this.showAlert(
        "Error",
        "All business question are mandatory."
      );
      return false;
    }

    const openDateWihtoutTime = Object.keys(this.state.operatingHours).find(day => this.state.operatingHours[day].open && (this.state.operatingHours[day].start_hour === null || this.state.operatingHours[day].end_hour === null || this.state.operatingHours[day].start_hour === "null" || this.state.operatingHours[day].end_hour === "null"))

    if (openDateWihtoutTime) {
      this.showAlert("Error", "Operating hours is mandatory for the days you are open.");
      return false;
    }

    return true;
  }

  updateBusinessInformation = () => {
    const header = {
      token: this.token
    };

    this.setState({
      loading: true
    });

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateBusinessInformationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData();

    formData.append('data[attributes]user_name', this.state.fullName);
    formData.append('data[attributes]email', this.state.email);
    formData.append('data[attributes]street', this.state.street);
    formData.append('data[attributes]city', this.state.city!.value);
    formData.append('data[attributes]state', this.state.state!.value);
    formData.append('data[attributes]country', this.state.country!.value);
    formData.append('data[attributes]zipcode', this.state.zipcode);
    formData.append('data[attributes]link_to_website', this.state.linkWebsite);
    formData.append('data[attributes]full_phone_number', this.state.phone_number);
    formData.append('data[attributes]bio', this.state.bio);
    formData.append('data[attributes]facebook_link', this.state.facebook);
    formData.append('data[attributes]instagram_link', this.state.instagram);

    Object.entries(this.state.operatingHours).forEach(([key, value]) => {
      formData.append(`data[attributes]${key}_open`, String(value.open));
      formData.append(`data[attributes]${key}_start_hour`, this.formatHourValueToSend(value.start_hour));
      formData.append(`data[attributes]${key}_end_hour`, this.formatHourValueToSend(value.end_hour));
    })

    if (this.state.uploadedFiles) {
      this.state.uploadedFiles.forEach((file) => {
        formData.append(`data[attributes][images][]`, file);
      })
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateBusinessAnswers(): boolean {
    this.setState({
      alertVisibility: false,
      alertMessage: "",
      loading: true,
    })

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const answers = [
      ...this.state.businessAnswersList,
      ...this.state.businessAnswersDropdownList.map(dropdownAnswer => {
        return {
          question_id: dropdownAnswer.question_id,
          answer: dropdownAnswer.answer?.value
        }
      })
    ]

    const httpBody = {
      account: {
        answers: answers
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateBusinessAnswersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateBusinessAnswersAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getBusinessQuestions() {
    const headers = {
      "Content-Type": configJSON.searchApiContentType
    };

    const getBusinessQuestionsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBusinessQuestionApiCallId = getBusinessQuestionsMsg.messageId;

    getBusinessQuestionsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBuisnessQuestionsAPiEndPoint
    );

    getBusinessQuestionsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getBusinessQuestionsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getBusinessQuestionsMsg.id, getBusinessQuestionsMsg);
  }
  // Customizable Area End
}
