import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type Plan = {
  id: string;
  type: string;
  attributes: {
    name: string;
    yearly_price: string;
    monthly_price: string;
    description: string;
    sub_title: string;
    subscription_type: "yearly" | "monthly" | "weekly";
    valid_up_to: string;
    expired: boolean;
    expiration_date: string;
    image_link: string | null,
    subscribed: boolean;
    plan_type: "Basic" | "Premium" | "Founder" | "Event" | "User Plan";
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  plan: Plan;
  isFreeTrial: boolean;
  isPopoverVisible: boolean;
  userType: "user" | "business";
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SubscriptionDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cancelSubscriptionCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      plan: {
        id: "",
        type: "",
        attributes: {
          name: "",
          yearly_price: "",
          monthly_price: "",
          description: "",
          sub_title: "",
          subscription_type: "yearly",
          valid_up_to: "",
          expiration_date: "",
          expired: false,
          image_link:"",
          subscribed: false,
          plan_type: "User Plan",
        }
      },
      token: "",
      userType: "user",
      isFreeTrial: false,
      isPopoverVisible: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token });
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const { plan, isFreeTrial } = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      
      this.setState({ 
        plan,
        isFreeTrial,
        userType: plan.attributes.plan_type.trim() === "User plan" ? "user" : "business"
      });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson && responseJson) {
        if (apiRequestCallId === this.cancelSubscriptionCallId) {
          this.loggedOutUser();
          this.goToLogin();
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    // Customizable Area End
  }

  // Customizable Area Start
  formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);

    const options: Intl.DateTimeFormatOptions = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(date);
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
 
    return `${month} ${day}, ${year} ${hours}:${minutes}`;
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  loggedOutUser() {
    const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
    localStorage.removeItem("token")
    msg.addData(
      getName(MessageEnum.SessionResponseData),
      ""
    );
    msg.addData(
      getName(MessageEnum.SessionResponseToken),
      ""
    );

    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  btnChangeSubscription = {
    onActionButton: () => this.togglePopover()
  }

  popoverProps = {
    onClose: () => this.setState({ isPopoverVisible: false }),
    primaryButtonAction: () => this.state.userType === "user" ? this.cancelSubscription() : this.goToCustomisableUserSubscriptions(),
    secondaryButtonAction: () => this.setState({ isPopoverVisible: false })
  }

  togglePopover() {
    this.setState(prevState => ({
      isPopoverVisible: !prevState.isPopoverVisible,
    }));
  }

  goToCustomisableUserSubscriptions() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationCustomisableUserSubscriptionsMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);
  }

  cancelSubscription(): boolean {
    this.setState({ isPopoverVisible: false })

    const header = {
      "Content-Type": configJSON.subscriptionApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.cancelSubscriptionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelSubscriptionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  // Customizable Area End
}
