import React from 'react'
import { Pressable, StyleSheet, TouchableOpacity, View } from 'react-native'

import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { runEngine } from "../../framework/src/RunEngine";

import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';

const logo = require('./logo.png').default;

interface Props {
  navigation: any;
  id: string;
  onPressFavourites?: () => void;
}

interface S { 
  visible: boolean;
}

interface SS { }

class AppHeader extends BlockComponent<Props, S, SS> {
  static instance: AppHeader;

  constructor(props: Props) {
    super(props);
    AppHeader.instance = this;

    this.state = {
      visible: false
    }
  }

  goToUserProfile = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationUserProfileMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToChooseCity = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLandingPageMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      prevScreen: window.location.pathname.replace("/", "")
    });
    this.send(msg);
  }

  goToHome = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  render() {
    return (
      <View style={styles.header}>
        <Pressable onPress={() => this.goToHome()}>
          <img src={logo} style={styles.logo} />
        </Pressable>

        <Emailnotifications2 {...this.props} visible={this.state.visible} />

        <View style={styles.headerActions}>
          {
            this.props.onPressFavourites  && (
              <TouchableOpacity testID="btnFavourite" style={styles.headerButton} onPress={this.props.onPressFavourites}>
                <FavoriteBorderIcon sx={styles.headerButtonIcon} />
              </TouchableOpacity>
            )
          }

          <TouchableOpacity testID="btnChooseCity" style={styles.headerButton} onPress={() => this.goToChooseCity()}>
            <LocationIcon sx={styles.headerButtonIcon} />
          </TouchableOpacity>

          <TouchableOpacity testID="btnNotifications" style={styles.headerButton} onPress={() => { this.setState({  visible: !this.state.visible }) }}>
            <NotificationsNoneIcon sx={styles.headerButtonIcon} />
          </TouchableOpacity>

          <TouchableOpacity testID="btnUserProfile" style={styles.headerButton} onPress={() => this.goToUserProfile()}>
            <PersonOutlineOutlinedIcon sx={styles.headerButtonIcon} />
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 40,
    paddingVertical: 16,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 12,
    zIndex: 10,
  },
  headerActions: {
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    width: 44,
    height: 44,
  },
  headerButton: {
    width: 44,
    height: 44,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 4
  },
  headerButtonIcon: { 
    fontSize: 24, 
    color: "#0F172A" 
  },
})

export default AppHeader;
