import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import eventEmitter from "../../../framework/src/Blocks/EventEmitter";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timeoutHandle: NodeJS.Timeout | null;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SplashscreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserAccountInformationCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      timeoutHandle: null, 
      token: "",
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const isSessionResponseMessage = getName(MessageEnum.SessionResponseMessage) === message.id;
    const isRestAPIResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;

    if (isSessionResponseMessage) {
      this.handleSessionResponse(message);
    }

    if (isRestAPIResponseMessage) {
      this.handleAPIResponse(message);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    eventEmitter.on("error500", () => {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationErrorscreenMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      
      this.send(msg);
    })
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    if (this.state.timeoutHandle) {
      clearTimeout(this.state.timeoutHandle);
    }
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  saveToken = (token: string) => {
    const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
  
    msg.addData(
      getName(MessageEnum.SessionResponseToken),
      token
    );

    this.send(msg);
  }

  private async handleSessionResponse(message: Message) {
    let sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));

    if (!sessionToken) {
      sessionToken = await localStorage.getItem("token");
      this.saveToken(sessionToken);
    }

    if (window.location.pathname === "/") {
      const timeout = setTimeout(() => {
        if (!sessionToken) {
          this.goToLogIn();
        } else {
          this.setState({
            token: sessionToken
          }, () => this.getUserAccountInformation())
        }
      }, configJSON.timeout)

      this.setState({
        timeoutHandle: timeout
      })
    }

  }

  private handleAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getUserAccountInformationCallId) {
      this.processUserAccountResponse(responseJson);
    } 
  }

  private processUserAccountResponse(responseJson: any) {
    if (!responseJson || responseJson.errors || !responseJson.data) {
      this.goToLogIn();
      return;
    }

    this.handleUserNavigation(responseJson.data.attributes);
  }

  private handleUserNavigation(attributes: any) {
    const hasSubscription = attributes.subscriptions.length > 0;

    if (attributes.type === "User") {
      if(hasSubscription) {
        this.navigateToLandingPage()
      } else {
        attributes.isFreeTrailsClaimed ? this.navigateToCustomisableUserSubscriptions() : this.navigateToFreetrial();
      }
    } else {
      hasSubscription ? this.navigateToUserProfile() : this.navigateToCustomisableUserSubscriptions();
    }
  }

  navigateToLandingPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLandingPageMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToUserProfile() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationUserProfileMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToFreetrial() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationFreeTrialMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToCustomisableUserSubscriptions() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationCustomisableUserSubscriptionsMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogIn() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getUserAccountInformation = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserAccountInformationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserAccountInformation
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
