import React from "react";
// Customizable Area Start
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { Typography, Button } from "@builder/component-library";
import PlanCard from "../../../components/src/PlanCard";
import { subscriptionImage, logo, checked } from "./assets";
import ArrowLeftIcon from '@mui/icons-material/ArrowBackOutlined';
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
  configJSON
} from "./CustomisableusersubscriptionsController";

import { SubscriptionListView } from "./SubscriptionList";

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={styles.customUserSubscriptionScreen}>
          {
            this.state.userType === "Business" ? (
              <ScrollView>
                <View style={styles.customBusinessSubscriptionMain}>
                  <View style={styles.customUserSubscriptionHeader}>
                    {
                      this.state.hasSubscription && (
                        <Pressable
                          style={styles.goBackButton}
                          testID="btnGoBack"
                          {...this.btnGoBackProps}
                        >
                          <ArrowLeftIcon sx={styles.icon} />
                        </Pressable>
                      )
                    }

                    <Typography variant="2xl" style={styles.customUserSubscriptionTitle}>PLANS & PRICING</Typography>
                  </View>

                  <Typography variant="xl" style={styles.customUserSubscriptionSubTitle}>Find your perfect plan for your business</Typography>

                  <View style={styles.subscriptionsContainer}>
                    {
                      this.state.plans.map((plan, index) => {
                        return (
                          <PlanCard
                            key={plan.attributes.name}
                            testID={index + "-btnSubscription"}
                            planName={plan.attributes.name}
                            planDescription={plan.attributes.sub_title}
                            benefits={plan.attributes.description.split(',')}
                            monthlyPrice={plan.attributes.monthly_price}
                            yearlyPrice={plan.attributes.yearly_price}
                            planType={plan.attributes.plan_type}
                            buttonActionText="Subscribe"
                            onActionButton={() => this.goToBillingInformation(plan)}
                          />
                        )
                      })
                    }
                  </View>
                </View>
               </ScrollView>
            ) : (
              <ScrollView>
                <View style={styles.customUserSubscriptionMain}>
                  <View style={styles.customUserSubscriptionContent}>
                    <View style={styles.subscriptionDetails}>
                      <View style={styles.header}>
                        <Pressable
                          testID="btnGoBack"
                          {...this.btnGoBackProps}
                        >
                          <ArrowLeftIcon sx={styles.icon} />
                        </Pressable>

                        <Typography variant="lg" style={styles.headerTitle}>Subscription information</Typography>
                      </View>

                      <View style={styles.planContainer}>
                        <View style={styles.planHeader}>
                            <img style={styles.planLogo} src={logo} />
                            <View>
                                <Typography variant="xl" style={styles.planName}>{this.state.userPlan.attributes.name}</Typography>
                                <Typography variant="xs" style={styles.planSubTitle}>{this.state.userPlan.attributes.description}</Typography>
                            </View>
                        </View>

                        <View style={styles.planBenefits}>
                          {
                            this.state.userPlan.attributes.description.split(',').length > 0 && this.state.userPlan.attributes.description.split(',').map(benefit => {
                              return (
                                <View key={benefit} style={styles.planBenefit}>
                                  <img style={styles.planChecked} src={checked as unknown as string} />
                                  <Text style={styles.planBenefitText}>
                                      {benefit.trim()}
                                  </Text>
                                </View>
                              )
                            })
                          }
                        </View>

                        <Typography variant="xs" style={styles.planAndPrice}>PLANS & PRICING</Typography>

                        {
                          !this.state.isFreeTrailsClaimed && (
                            <Typography variant="base" data-test-id="freeTrialText"style={styles.planText}>Free 1 week trial</Typography>
                          )
                        }

                        <View style={styles.planPaymentValue}>
                          <Text  style={styles.planPricePeriodText}>
                              <Text style={styles.planPriceText}>${this.state.userPlan.attributes.yearly_price}</Text> / year
                          </Text>
                        </View>
                            
                        
                        <View style={styles.planActionContainer}>
                          <Button
                            testID="btnStartFreeTrial"
                            textStyle={styles.planButtonText}
                            style={styles.planActionButton}
                            text={(this.state.userType === "User" && !this.state.isFreeTrailsClaimed) ? "Start free trial" : "Subscribe"}
                            onPress={() => this.goToBillingInformation(this.state.userPlan)}
                          />
                        </View>
                    </View>
                    </View>
                    
                    <View style={styles.subscriptionImageContanier}>
                      <img src={subscriptionImage} style={styles.subscriptionImage} />
                    </View>
                  </View>
                </View>
              </ScrollView>
            )
          }
      </View>
      // Customizable Area End
    );
  }
}

const styles = StyleSheet.create({
  customUserSubscriptionScreen: {
    width: "100%",
    height: "100%",
    backgroundColor: "#9BCE7B",
    flex: 1
  },
  scrollContainer: {
    minHeight: "100%",
    flex: 1
  },
  customBusinessSubscriptionMain: {
    position: "relative",
    flex: 1,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#9BCE7B",
    padding: 40,
    width: "100%",
    height: "100%",
  },
  customUserSubscriptionMain: {
    width: "100%",
    maxWidth: 1200,
    minHeight: "100%",
    padding: 40,
    backgroundColor: "#9BCE7B",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: "auto"
  },
  customUserSubscriptionContent: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  customUserSubscriptionHeader: {
    flexDirection: "row",
    marginBottom: 30,
    marginTop: 110,
    alignItems: "center",
  },
  goBackButton: {
    width: 32,
    height: 32,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
  },
  customUserSubscriptionTitle: {
    fontWeight: "700",
    color: "#4C7731",
    marginLeft: 8
  },
  customUserSubscriptionSubTitle: {
    fontWeight: "400",
    color: "#0F172A"
  },
  subscriptionsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 40,
    flexWrap: "wrap"
  },
  subscriptionImageContanier: {
    width: 676,
    height: 650,
    borderRadius: 55,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#D9D9D933"
  },
  subscriptionImage: {
    width: 534,
    height: 534
  },
  subscriptionDetails: {
    width: "100%",
    maxWidth: 375
  },
  header: {
    flexDirection: "row",
    marginBottom: 20
  },
  icon: {
    fontSize: 24,
    color: "#0F172A"
  },
  headerTitle: {
    fontWeight: "700",
    color: "#1E293B",
    marginLeft: 8
  },

  planContainer: {
    width: "100%",
    maxWidth: 375,
    borderRadius: 8,
    padding: 16,
    backgroundColor: "#FFFFFF80",
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#CBD5E1",
    borderStyle: "solid"
  },
  planHeader: {
    flexDirection: "row"
  },
  planLogo: {
    width: 44,
    height: 44,
    marginRight: 12
  },
  planName: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#000000"
  },
  planSubTitle: {
    fontFamily: "Inter",
    fontWeight: "400", 
    color: "#4C7731"
  },
  planBenefitText: {
    fontFamily: "Inter",
    fontSize: 14, 
    fontWeight: "400", 
    color: "#000"
  },
  planPricePeriodText: {
    alignItems: "center",
    fontFamily: "Inter",
    fontSize: 14, 
    fontWeight: "700", 
    color: "#64748B"
  },
  planChecked: {
    width: 16,
    height: 16,
    marginRight: 12
  },
  planBenefits: {
    flex: 1,
    marginTop: 20
  },
  planBenefit: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8
  },
  planPaymentValue: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 30
  },
  planPriceText: {
    fontWeight: "700", 
    color: "#000", 
    fontSize: 24
  },
  planActionButton: {
    flex: 1,
    backgroundColor: "#8861A9"
  },
  planButton: {
    backgroundColor: "#8861A9"
  },
  planButtonText: {
    color: "#FFFFFF"
  },
  planActionContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 30
  },
  planAndPrice: {
    fontWeight: "700",
    color: "#4C7731",
    marginTop: 30
  },
  planText: {
    fontWeight: "700",
    color: "#0F172A",
    marginTop: 30,
  }
})

// Customizable Area Start
// Customizable Area End
