import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import DeleteAccountIcon from '@mui/icons-material/WarningAmberOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import React, { createRef } from "react";
import { ScrollView } from "react-native";

export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

type Plan = {
  id: number;
  name: string;
  description: string;
  price: string;
  valid_up_to: string;
  sub_title: string;
  subscription_type: string;
  account_type: string;
  yearly_price: string;
  plan_type: string;
  weekly_price: string | null;
  monthly_price: string | null;
  no_of_uploads: number
}
const config = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  haveSubscribed: boolean;
  updateFieldFirstName: string;
  updateFieldLastName: string;
  isFreeTrial: boolean;
  updateFieldEmail: string;
  subscription_type: string;
  max_upload_photos: number;
  loading: boolean;
  uploadLoading: boolean;
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  operatingHours: {
    [key: string]: {
      open: boolean;
      start_hour: string | null,
      end_hour: string | null
    }
  };
  linkWebsite: string | null;
  account_id: number | null;
  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  subscription_end_date: string;
  password: string;
  oldPassword: string;
  zipCode: string;
  phoneNumber: string;
  address: string;
  city: string;
  street: string;
  state: string;
  country: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];
  images: string[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;

  alertVisibility: boolean;
  alertMessage: string;
  inAppNotifications: boolean;
  popoverState: {
    visibility: boolean;
    title: string;
    subTitle?: string;
    icon?: any;
    iconColor?: string;
    primaryButtonText: string;
    primaryButtonColor: string;
    secondaryButtonText: string;
    onClose: () => void;
    primaryButtonAction: () => void;
    secondaryButtonAction: () => void;
  },
  dropdownMenuVisible: boolean;
  uploadedPictureImage: string;
  uploadedPictureFile: File | null;
  uploadPictureVisible: boolean;
  sideSectionVisible: boolean;
  imgCropSize: number;
  currentSubscription: Plan | null;
  business_answers: Array<{question_id: number ,answer: string | number }>
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  canvasRef = createRef<HTMLCanvasElement>();
  customisableUserProfileScrollRef = createRef<ScrollView>();
  inputProfilePictureRef: React.RefObject<HTMLInputElement>;
  getNotificationPreferencesCallId: string = "";
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  updateUserPictureCallId: string = "";
  deleteUserPictureCallId: string = "";
  updateNotificationPreferencesCallId: string = "";
  deleteAccountCallId: string = "";
  deactivateAccountCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  cancelSubscriptionCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      haveSubscribed: false,
      updateFieldFirstName: "",
      updateFieldLastName: "",
      isFreeTrial: false,
      updateFieldEmail: "",
      images: [],
      subscription_type: "Basic",
      max_upload_photos: 0,
      loading: false,
      uploadLoading: false,
      profile: {},
      profileImageUrl: "",
      fields: [],
      operatingHours: {},
      linkWebsite: "",
      account_id: null,
      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      subscription_end_date: "",
      password: "",
      oldPassword: "",
      zipCode: "",
      address: "",
      city: "",
      street: "",
      state: "",
      country: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      inAppNotifications: false,
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      alertVisibility: false,
      alertMessage: "",
      popoverState: {
        visibility: false,
        title: "",
        subTitle: "",
        primaryButtonText: "",
        primaryButtonColor: "",
        secondaryButtonText: "",
        onClose: () => this.closePopover(),
        primaryButtonAction: () => {},
        secondaryButtonAction: () => {}
      },
      dropdownMenuVisible: false,
      uploadedPictureImage: "",
      uploadedPictureFile: null,
      uploadPictureVisible: false,
      sideSectionVisible: false,
      imgCropSize: 100,
      currentSubscription: null,
      business_answers: []
      // Customizable Area End
    };
    // Customizable Area Start
    this.inputProfilePictureRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start

    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const isSessionResponseMessage = getName(MessageEnum.SessionResponseMessage) === message.id;
    const isRestAPIResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;

    if (isSessionResponseMessage) {
      this.handleSessionResponse(message);
    }

    if (isRestAPIResponseMessage) {
      this.handleAPIResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  showAlert(alertType: string, alertMessage: string) {
    if (this.customisableUserProfileScrollRef.current) {
      this.customisableUserProfileScrollRef.current.scrollTo({
        y: 0,
        animated: true
      })
    }

    this.setState({
      alertVisibility: true,
      alertMessage: alertMessage
    })
  }

  private handleSessionResponse(message: Message) {
    const sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));
    if (!sessionToken || sessionToken === "null") {
      this.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
    } else {
      this.token = sessionToken
    }

    const testToken = window.localStorage.getItem("testToken");

    if (testToken) {
      this.token = testToken;
    }

    runEngine.debugLog("TOKEN1", this.token);
    this.getCustomizableProfile();
    this.getNotificationPreferences();
  }

  private handleAPIResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.setState({
      loading: false
    })

    if (responseJson && !responseJson.errors) {

      this.processApiResponse(message, responseJson);
    }
  }

  private processApiResponse(message: Message, responseJson: any) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (apiRequestCallId) {
      case this.getCustomizableProfileCallId:
        this.handleProfileResponse(responseJson);
        break;
      case this.updateCustomizableProfileCallId:
        this.handleUpdateProfileResponse(responseJson);
        break;
      case this.getNotificationPreferencesCallId:
        this.handleNotificationPreferencesResponse(responseJson);
        break;
      case this.deleteAccountCallId:
        this.handleAccountDeletion();
        break;
      case this.deactivateAccountCallId:
        this.handleAccountDeletion("deactivate");
        break;
      case this.updateUserPictureCallId:
      case this.deleteUserPictureCallId:  
        this.handleUpdateUserPictureResponse(responseJson);
        break;
      default:
        this.checkOtherResponses(message);
        this.checkProfileAccount(message);
    }
  }

  convertScheduleToDate = (schedule: any) => {
    const newSchedule: any = {}

    for (const day in schedule) {
      const dayDate = schedule[day];

      newSchedule[day] = {
        ...dayDate,
        start_hour: dayDate.start_hour,
        end_hour: dayDate.end_hour
      }
    }

    return newSchedule
  }

  private handleProfileResponse(responseJson: any) {
    const userInfo = responseJson.data.attributes;

    const currentSubscription = userInfo.subscriptions.find((subscription: any) => subscription.id === userInfo.current_activate_subscription_id);
    
    this.setState({
      business_answers: userInfo.business_answers, 
      account_id: responseJson.data.id,
      fullName: this.getFullName(userInfo),
      first_name: userInfo.first_name || "",
      lastName: userInfo.last_name || "",
      email: userInfo.email || "",
      subscription_end_date: userInfo.subscription_end_date || "",
      zipCode: userInfo.zipcode || "",
      user_type: userInfo.type,
      street: userInfo.street || "",
      city: userInfo.city || "",
      state: userInfo.state || "",
      country: userInfo.country || "",
      operatingHours: this.convertScheduleToDate(userInfo.schedule),
      linkWebsite: userInfo.link_to_website || "",
      phoneNumber: userInfo.full_phone_number || "",
      profilePicture: userInfo.profile_image ? config.baseURL + userInfo.profile_image[0]?.url || "" : "",
      biography: userInfo.bio || "",
      facebook: userInfo.facebook_link || "",
      instagram: userInfo.instagram_link || "",
      subscription_type: userInfo.current_activate_subscription,
      images: this.getImages(userInfo),
      haveSubscribed: userInfo.subscriptions.length > 0,
      isFreeTrial: !userInfo.isFreeTrailsClaimed || false,
      currentSubscription: currentSubscription,
      max_upload_photos: userInfo.no_of_uploads
    })
  }

  private handleUpdateProfileResponse(responseJson: any) {
    const userInfo = responseJson.data.attributes;
    this.setState({
      fullName: `${userInfo.first_name} ${userInfo.last_name}`,
      first_name: userInfo.first_name,
      lastName: userInfo.last_name,
      email: userInfo.email,
      zipCode: userInfo.zipcode,
      sideSectionVisible: false,
    })
  }

  private handleUpdateUserPictureResponse(responseJson: any) {
    const userInfo = responseJson.data.attributes;

    this.setState({
      profilePicture: userInfo.profile_image ? config.baseURL + userInfo.profile_image[0]?.url || "" : "",
      dropdownMenuVisible: false,
      uploadPictureVisible: false
    });
  }

  private handleNotificationPreferencesResponse(responseJson: any) {
    const { in_app_notification } = responseJson.data.attributes

    this.setState({
      inAppNotifications: in_app_notification,
    })
  }

  private handleAccountDeletion(type?: "delete" | "deactivate") {
    this.cancelSubscription();
    this.loggedOutUser();
    this.goToLogin(type);
  }

  private getFullName(userInfo: any): string {
    return userInfo.type === "User" ? `${userInfo.first_name} ${userInfo.last_name}` : userInfo.user_name || "";
  }

  private getImages(userInfo: any): string[] {
    return userInfo.type === "User" ? [] : userInfo.images?.map((image: { url: string }) => image.url) || [];
  }

  closePopover = () => {
    this.setState({
      popoverState: {
        ...this.state.popoverState,
        visibility: false,
      }
    })
  }

  handleOpenSelectProfilePicture = () => {
    if (this.inputProfilePictureRef) {
      this.inputProfilePictureRef.current?.click();
    }
  }

  handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const MAX_SIZE_MB = 5 * 1024 * 1024;

      if (file.size > MAX_SIZE_MB) {
        this.showAlert("Error", "File must be no larger than 5MB.");
        this.setState({
          uploadPictureVisible: false,
        })
        return;
      }

      this.setState({
        uploadLoading: true,
        uploadPictureVisible: true,
      })

      setTimeout(() => {
        this.setState({
          uploadedPictureImage: URL.createObjectURL(file),
          uploadedPictureFile: file,
          alertMessage: "",
          alertVisibility: false,
          uploadLoading: false
        });
      }, 500)

    }
  }

  handleChangeCropSize = (type: "increment" | "decrement") => {
    if (type === "decrement" && this.state.imgCropSize > 25) {
      this.setState({
        imgCropSize: this.state.imgCropSize - 25
      })
    }
    if (type === "increment" && this.state.imgCropSize < 100) {
      this.setState({
        imgCropSize: this.state.imgCropSize + 25
      })
    }
  }

  handleOpenEditProfile = () => {
    if (this.state.user_type === "User") {
      this.setState({
        sideSectionVisible: true,
        alertVisibility: false,
        alertMessage: "",
        updateFieldFirstName: this.state.first_name,
        updateFieldLastName: this.state.lastName,
        updateFieldEmail: this.state.email,
      })
      this.txtInputFirstNameProps.onChangeText(this.state.first_name);
      this.txtInputLastNameProps.onChangeText(this.state.lastName);
      this.txtInputEmailProps.onChangeText(this.state.email);

    } else {
      this.goToUpdateBusinessInformation();
    }
  }

  handleCloseSideSection = () => {
    this.setState({
      sideSectionVisible: false,
      alertMessage: "",
      alertVisibility: false,
    })
  }

  handleDeleteAccount = () => {
    this.setState({
      popoverState: {
        ...this.state.popoverState,
        visibility: true,
        icon: <DeleteAccountIcon sx={{fontSize: 24, color: "#DC2626"}} />,
        iconColor: configJSON.deleteAccountPopoverIconColor,
        title: configJSON.deleteAccountPopoverTitle,
        subTitle: configJSON.deleteAccountPopoverSubTitle,
        primaryButtonText: configJSON.deleteAccountPopoverPrimaryButtonText,
        primaryButtonColor: configJSON.deleteAccountPopoverPrimaryButtonColor,
        secondaryButtonText: configJSON.deleteAccountPopoverSecondaryButtonText,
        primaryButtonAction: () => this.doDeleteAccount(),
        secondaryButtonAction: () => this.doLogOut()
      }
    })
  }

  handleDeactivateAccount = () => {
    this.setState({
      popoverState: {
        ...this.state.popoverState,
        visibility: true,
        icon: <DeleteAccountIcon sx={{fontSize: 24, color: "#DC2626"}} />,
        iconColor: configJSON.deactivateAccountPopoverIconColor,
        title: configJSON.deactivateAccountPopoverTitle,
        subTitle: configJSON.deactivateAccountPopoverSubTitle,
        primaryButtonText: configJSON.deactivateAccountPopoverPrimaryButtonText,
        primaryButtonColor: configJSON.deleteAccountPopoverPrimaryButtonColor,
        secondaryButtonText: configJSON.deleteAccountPopoverSecondaryButtonText,
        primaryButtonAction: () => this.doDeactivateAccount(),
        secondaryButtonAction: () => this.doLogOut()
      }
    })
  }
  
  handleLogoutAccount = () => {
    this.setState({
      popoverState: {
        ...this.state.popoverState,
        visibility: true,
        icon: <LogoutIcon sx={{fontSize: 24, color: "#8861A9"}} />,
        title: configJSON.logoutAccountPopoverTitle,
        subTitle: "",
        primaryButtonText: configJSON.logoutAccountPopoverPrimaryButtonText,
        primaryButtonColor: "#8861A9",
        secondaryButtonText: configJSON.logoutAccountPopoverSecondaryButtonText,
        primaryButtonAction: () => this.doLogOut(),
        secondaryButtonAction: () => this.closePopover()
      }
    })
  }

  goToSubscriptionInformation() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), 
      {  
        plan: {
          id: this.state.currentSubscription?.id,
          type: this.state.currentSubscription?.subscription_type,
          attributes: {
            name: this.state.currentSubscription?.name,
            yearly_price: this.state.currentSubscription?.yearly_price,
            monthly_price: this.state.currentSubscription?.monthly_price ||  this.state.currentSubscription?.price,
            description: this.state.currentSubscription?.description,
            sub_title: this.state.currentSubscription?.sub_title,
            subscription_type: this.state.currentSubscription?.subscription_type,
            valid_up_to: this.state.currentSubscription?.valid_up_to,
            plan_type: this.state.currentSubscription?.plan_type,
            expiration_date: this.state.subscription_end_date
          }
        }, 
        isFreeTrial: this.state.isFreeTrial && this.state.user_type === "User"
      }
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg);
  }

  goToActivityLogs() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ActivityLog");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  popoverProps = {
    onClose: () =>  this.state.popoverState.onClose(),
    primaryButtonAction: () =>  this.state.popoverState.primaryButtonAction(),
    secondaryButtonAction: () => this.state.popoverState.secondaryButtonAction(),
  };

  switchInputInAppNotificationProps = {
    onValueChange: (value: boolean) => {
      this.setState({ inAppNotifications: value });
      this.updateNotificationPreferences({ in_app_notification: value });
      //@ts-ignore
      this.switchInputInAppNotificationProps.value = value;
    },
  };

  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ updateFieldFirstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    },
  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ updateFieldLastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    },
  };

  txtInputEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ updateFieldEmail: text.trim() });

      //@ts-ignore
      this.txtInputEmailProps.value = text.trim();
    },
  };

  doDeleteAccount(): boolean {
    this.closePopover()

    this.setState({
      alertVisibility: false,
    })

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteAccountCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  doDeactivateAccount(): boolean {
    this.closePopover();

    this.setState({
      alertVisibility: false,
    });

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deactivateAccountCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deactivateAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  doLogOut(): boolean {
    this.closePopover()

    this.setState({
      alertVisibility: false,
    })

    this.loggedOutUser();
    this.goToLogin();

    return true;
  }

  goToLogin(type?: "delete" | "deactivate") {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    if (type) {
      msg.addData(getName(MessageEnum.NavigationPayLoadMessage), { message: "Account Deactivated Successfully" });
    }

    this.send(msg);
  }

  goToUpdateBusinessInformation = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationUpdateBusinessInfoMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      account_id: this.state.account_id,
      fullName: this.state.fullName,
      email: this.state.email,
      zipCode: this.state.zipCode,
      user_type: this.state.user_type,
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      operatingHours: this.state.operatingHours,
      linkWebsite: this.state.linkWebsite,
      phone_number: this.state.phoneNumber,
      bio: this.state.biography,
      facebook: this.state.facebook,
      instagram: this.state.instagram,
      subscription_type: this.state.subscription_type,
      images: this.state.images,
      max_upload_photos: this.state.max_upload_photos,
      business_answers: this.state.business_answers
    });
    this.send(msg);
  }

  goToChangePassword = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationChangePasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  loggedOutUser() {
    const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
    localStorage.removeItem("token")
    msg.addData(
      getName(MessageEnum.SessionResponseData),
      ""
    );
    msg.addData(
      getName(MessageEnum.SessionResponseToken),
      ""
    );

    this.send(msg);
  }

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCustomisableProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNotificationPreferences = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getNotificationPreferencesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationPreferencesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateNotificationPreferences = ({in_app_notification}: { in_app_notification: boolean }) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateNotificationPreferencesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateNotificationPreferencesEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        settings: {
          in_app_notification,
        }
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    this.setState({
      loading: false
    });

    if (
      this.state.updateFieldFirstName.length === 0 ||
      this.state.updateFieldLastName.length === 0 ||
      this.state.updateFieldEmail.length === 0
    ) {
      this.showAlert("Error", configJSON.errorBlankField);
      return false;
    }


    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            first_name: this.state.updateFieldFirstName,
            last_name: this.state.updateFieldLastName,
            email: this.state.updateFieldEmail,
          }
        }
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  uploadUserPicture = () => {
    const header = {
      token: this.token
    };

    if (this.state.uploadedPictureFile === null) {
      return;
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateUserPictureCallId = requestMessage.messageId;

    const canvas = this.canvasRef.current;
    const ctx = canvas?.getContext("2d");
    const img = new Image();

    if (!canvas) return;

    this.setState({
      loading: true
    });
 
    img.onload = () => {
      const minDimension = Math.min(img.width, img.height);
      const diameter = (minDimension * this.state.imgCropSize) / 100;
      const radius = diameter / 2;

      canvas.width = diameter;
      canvas.height = diameter;

      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.beginPath();
        ctx.arc(radius, radius, radius, 0, Math.PI * 2);
        ctx.closePath();
        ctx.clip();

        ctx.drawImage(
          img,
          (img.width - diameter) / 2,
          (img.height - diameter) / 2,
          diameter,
          diameter,
          0,
          0,
          diameter,
          diameter
        )
      }

      const croppedImageDataUrl = canvas.toDataURL("image/png");

      const blob = this.dataURLtoBolb(croppedImageDataUrl);
      const file = new File([blob], "profile_image.png", { type: "image/png"});

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateUserPictureEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const formData = new FormData();

      formData.append("data[attributes]profile_image[]", file);
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPatchMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    img.src = this.state.uploadedPictureImage;
  };

  dataURLtoBolb = (dataURL: string) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  removeUserPicture = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    this.setState({
      loading: true
    })

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteUserPictureCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteUserPictureEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  cancelSubscription() {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.cancelSubscriptionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelSubscriptionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
