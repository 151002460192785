import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
export interface IFreeTrailSubscriptionResponse {
  status: boolean;
  message: string;
  subscription_details: {
    plan: string;
    billing_amount: string;
    next_bill_date: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  freeTrailSubscriptionStatus: IFreeTrailSubscriptionResponse;
  token: string;
  loading: boolean;
  userPlanInformation: {
    id: string;
    name: string;
    description: string;
    sub_title: string;
    subscription_type: string;
    valid_up_to: string;
    yearly_price: string;
    plan_type: string;
    expired: boolean;
    monthly_price: string;
    image_link: string | null;
    subscribed: boolean;
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FreeTrialController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubscriptionsInfoCallId: string = '';
  startFreeTrialCallId: string = '';
  apiCallIdfatchFreetrialStatus: string = '';
  apiCallIdProceedPayment: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      freeTrailSubscriptionStatus: {
        status: false,
        message: '',
        subscription_details: {
          plan: '',
          billing_amount: '',
          next_bill_date: '',
        },
      },
      loading: false,
      token: "",
      userPlanInformation: {
        id: "",
        name: "",
        description: "",
        sub_title: "",
        subscription_type: "",
        valid_up_to: "",
        yearly_price: "",
        plan_type: "",
        expired: false,
        monthly_price: "",
        image_link:  null,
        subscribed: false
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      if (!token) {
        token = await getStorageData("authtoken");
      }

      this.setState({ token: token }, () => {
        this.fetchSubscriptionsInformation();
      });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId == this.apiCallIdfatchFreetrialStatus) {
          if (responseJson.data && !errorReponse) {
            this.setState({ freeTrailSubscriptionStatus: responseJson.data });
          }
        } else if (apiRequestCallId === this.getSubscriptionsInfoCallId) {
          if (responseJson.data && responseJson.data.length >= 1) {
            this.setState({
              userPlanInformation: {
                ...responseJson.data[0].attributes,
                id: responseJson.data[0].id
              }
            })
          }
        } else if (apiRequestCallId === this.startFreeTrialCallId) {
          if (apiRequestCallId && responseJson && responseJson.stripe_session && !errorReponse) {
            window.location.replace(responseJson.stripe_session.url);
          }
          this.setState({
            loading: false
          })
        }

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.fatchFreetrialStatus();
    this.getToken();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  btnProceedPay = {
    onPress: () => this.startFreeTrial()
  }

  fetchSubscriptionsInformation = () => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.applicationJsonContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionsInfoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchSubscriptionInfoAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  startFreeTrial = () => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.applicationJsonContentType,
    };

    if (this.state.userPlanInformation.id === "") {
      return;
    }

    this.setState({
      loading: true
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.startFreeTrialCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.startFreeTrialEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const subscription_type = this.state.userPlanInformation.yearly_price && this.state.userPlanInformation.yearly_price !== "0.0" ? "yearly" : "monthly";
    const price = this.state.userPlanInformation.yearly_price && this.state.userPlanInformation.yearly_price !== "0.0" ? this.state.userPlanInformation.yearly_price : this.state.userPlanInformation.monthly_price;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        subscription_id: this.state.userPlanInformation.id,
        success_url: window.location.origin + `/SuccessSubscriptionbilling2?subscription_id=${this.state.userPlanInformation.id}&subscription_name=${this.state.userPlanInformation.name}&subscription_type=${subscription_type}&subscription_price=${price}&plan_type=${this.state.userPlanInformation.plan_type}`,
        cancel_url: window.location.origin + "/FreeTrial",
        price,
        subscription_type: subscription_type
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    setStorageData("selectedPlan", JSON.stringify(this.state.userPlanInformation));
    setStorageData("selectedSubscriptionId", this.state.userPlanInformation.id);
    setStorageData("authtoken", this.state.token);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fatchFreetrialStatus = () => {
    const userToken = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!userToken || !userId) {
      return;
    }

    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.apiCallIdfatchFreetrialStatus = requestMessage.messageId;

    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fatchFreeTrailStatusApiEndPoint}/${userId}` // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) // here add header data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod // api method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  cancelFreeTrialNotificationModal = () => {
    this.setState(prevState => ({
      freeTrailSubscriptionStatus: {
        ...prevState.freeTrailSubscriptionStatus,
        status: false
      }
    }));
  }
  // Customizable Area End
}
