import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet, ScrollView, TouchableOpacity, Platform } from "react-native";
import CloseIcon from '@mui/icons-material/CloseOutlined';
// Customizable Area End

import Emailnotifications2Controller, {
  Props,
  configJSON,
} from "./Emailnotifications2Controller";

export default class Emailnotifications2 extends Emailnotifications2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={[styles.container, this.props.visible && styles.containerAnimated]}>
        <ScrollView>
          {
            this.state.notifications.map(notification => {
              return (
                <View key={notification.id} style={styles.notificationContainer}>
                  <TouchableOpacity testID={`btnDeleteNotification-${notification.id}`} onPress={() => this.deleteNotification(notification.id)}>
                    <CloseIcon sx={{ fontSize: 18 }} />
                  </TouchableOpacity>

                  <View style={styles.notificationContent}>
                    <Typography variant="base" style={styles.title}>{notification.headings}</Typography>
                    <Typography variant="sm" style={styles.subTitle}>{notification.contents}</Typography> 
                  </View>

                  <img src={notification.profile_image} style={styles.profilePicture} /> 
                </View>
              )
            })
          }
        </ScrollView>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 90,
    right: -300,
    zIndex: 999,
    maxHeight: 380,
    opacity: 0,
    ...Platform.select({
      web: {
        transition: 'right 0.3s ease, max-width 0.3s ease, opacity 0.3s ease',
      }
    }),
  },
  containerAnimated: {
    opacity: 1,
    right: 30
  },
  notificationContainer: {
    width: 327,
    backgroundColor: "#9BCE7B",
    flexDirection: "row",
    borderRadius: 6,
    paddingHorizontal: 20,
    marginVertical: 8,
    marginHorizontal: 10,
    paddingVertical: 16,
    ...Platform.select({
      web: {
        gap: 8,
        boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.35)",
      }
    }),

  },
  notificationContent: {
    flex: 1,
  },
  profilePicture: {
    width: 32,
    height: 32,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: "#F0E5FF",
    borderStyle: "solid"
  },
  title: {
    fontWeight: "700",
    color: "#0F172A",
    marginBottom: 2
  },
  subTitle: {
    fontWeight: "400",
    color: "#0F172A"
  }
});


// Customizable Area End
