import React, { useState } from 'react'
import {
    Calendar,
    EventRenderer,
    ICalendarEventBase,
    Mode,
} from 'react-native-big-calendar'
import { View, TouchableOpacity, RecursiveArray, ViewStyle } from 'react-native'
import { CalendarHeader, CalendarHeaderStyleProps } from './CalendarHeader'
import { EventCard } from '../EventCard/EventCard'
import { Size } from '../../utils/constants'

const customEventRenderer: EventRenderer<Events> = (
    event,
    touchableOpacityProps,
) => {
    return (
        <TouchableOpacity
            {...touchableOpacityProps}
            style={[
                ...(touchableOpacityProps.style as RecursiveArray<ViewStyle>),
                {
                    shadowOffset: { width: 0, height: 0 },
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                },
            ]}
        >
            <EventCard
                size={Size.Max}
                containerStyle={{ width: '100%', height: '100%' }}
                title={event.title}
                participant={event.participant}
            />
        </TouchableOpacity>
    )
}

interface Events extends ICalendarEventBase {
    participant: string
}

interface FullCalendarProps {
    events: Events[]
    initialMode?: 'day' | 'week' | 'month'
    height: number
    initialDate?: Date
    contentContainerStyle?: ViewStyle
    headerStyle?: CalendarHeaderStyleProps
}

const MIN_HOUR_HEIGHT = 40

const getMinuteOfDay = (date) => {
    const hours = date.getHours()
    const minutes = date.getMinutes()
    return hours * 60 + minutes - 30
}

const buildControlProps = (mode, height) => {
    const hourRowHeight = Math.max(height / 24, MIN_HOUR_HEIGHT)
    const controlProps = {}
    controlProps['hourRowHeight'] = hourRowHeight
    if (mode == 'day') {
        if (mode === 'day') {
            controlProps['renderHeader'] = () => null
            controlProps['renderEvent'] = customEventRenderer
            controlProps['height'] = height + hourRowHeight * 3
        } else if (mode == 'month') {
        } else if (mode === 'month') {
            controlProps['theme'] = {
                palette: {
                    primary: {
                        main: 'purple',
                        contrastText: 'black',
                    },
                },
            }
            controlProps['height'] = height
        } else if (mode == 'week') {
        } else if (mode === 'week') {
            controlProps['theme'] = {
                palette: {
                    primary: {
                        main: 'purple',
                        contrastText: '#fff',
                    },
                },
            }
            controlProps['height'] = height + hourRowHeight * 3
        }
        return controlProps
    }
}

const FullCalendar = (props: FullCalendarProps) => {
    const {
        initialDate = new Date(),
        events = [],
        initialMode = 'month',
        height,
        contentContainerStyle,
        headerStyle,
    } = props
    const [date, setDate] = useState(initialDate)
    const [mode, setMode] = useState<Mode>(initialMode)

    const controlProps = buildControlProps(mode, height - 20)

    return (
        <View
            style={[
                { flex: 1, backgroundColor: 'white' },
                contentContainerStyle,
            ]}
        >
            <CalendarHeader
                date={date}
                mode={mode}
                setMode={setMode}
                setDate={setDate}
                containerStyle={headerStyle?.containerStyle}
                dateTextStyle={headerStyle?.dateTextStyle}
                controlContainerStyle={headerStyle?.controlContainerStyle}
                segmentedControlContainerStyle={
                    headerStyle?.segmentedControlContainerStyle
                }
            />
            <View
                style={{
                    width: '100%',
                    height: height,
                    overflow: 'hidden',
                }}
            >
                <Calendar
                    date={date}
                    events={events}
                    height={height - 20}
                    mode={mode}
                    weekStartsOn={1}
                    eventCellStyle={{ backgroundColor: 'rgb(241, 234, 253)' }}
                    eventCellTextColor="black"
                    scrollOffsetMinutes={getMinuteOfDay(date)}
                    {...controlProps}
                />
            </View>
        </View>
    )
}

export { Events, FullCalendarProps, FullCalendar }
