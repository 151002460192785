import React, { useEffect, useRef, useState } from 'react'
import {
    View,
    StyleProp,
    ViewStyle,
    StyleSheet,
    Image,
    Pressable,
    FlatList,
    FlatListProps,
} from 'react-native'
import DotPagination, {
    DotPaginationProps,
} from '../Pagination/DotPagination/DotPagination'
import leftArrowIcon from '../../assets/images/left-arrow.png'
import rightArrowIcon from '../../assets/images/right-arrow.png'

export interface CarouselProps extends Omit<FlatListProps<any>, 'renderItem'> {
    items: any[]
    renderItem: (
        item: any,
        index: number,
        dotPagination: React.ReactNode,
    ) => React.ReactNode
    autoPlay?: boolean
    autoPlayIntervalMS?: number
    loop?: boolean
    onIndexChange?: (index: number) => void
    testID?: string
    containerStyle?: StyleProp<ViewStyle>
    contentContainerStyle?: StyleProp<ViewStyle>
    paginationType?: 'dots' | 'arrows' | 'both'
    paginationViewStyle?: [StyleProp<ViewStyle>, StyleProp<ViewStyle>]
    paginationArrowIcons?: ArrowIcons
    paginationDotIcon?: DotPaginationProps
    maxItemsPerView?: number
}

export interface ArrowIcons {
    arrows: [ArrowIcon, ArrowIcon]
    height: number
    width: number
}

export interface ArrowIcon {
    icon: React.ReactNode
    containerStyle?: StyleProp<ViewStyle>
    onPress?: () => void
}

const Carousel = (props: CarouselProps) => {
    const {
        items,
        renderItem,
        autoPlay,
        autoPlayIntervalMS = 3000,
        containerStyle,
        contentContainerStyle,
        paginationType,
        paginationArrowIcons,
        loop,
        testID,
        paginationDotIcon,
        onIndexChange,
        maxItemsPerView,
        ...rest
    } = props

    const interval = useRef(undefined)
    const flatListRef = useRef<FlatList>()

    const [layout, setLayout] = useState(undefined)
    const [cardLayout, setCardLayout] = useState(undefined)
    const [currentScrollIndex, setCurrentScrollIndex] = useState(0)
    const [refreshInterval, setRefreshInterval] = useState(undefined)
    const [initialItemCount, setInitialItemCount] = useState(undefined)

    useEffect(() => {
        let initialItemCount = items.length

        if (
            layout &&
            cardLayout &&
            cardLayout.width * initialItemCount > layout.width
        ) {
            // Prevent rounding to 0. Minimum of 1 item per view is required.
            initialItemCount = Math.max(
                Math.floor(layout.width / cardLayout.width),
                1,
            )
        }

        setInitialItemCount(initialItemCount)
    }, [layout?.width, cardLayout?.width, cardLayout?.left])

    useEffect(() => {
        if (!autoPlay || !cardLayout) {
            return
        }

        interval.current = setInterval(() => {
            goForward(false)
        }, autoPlayIntervalMS)

        return () => {
            clearInterval(interval.current)
        }
    }, [layout, refreshInterval, currentScrollIndex, cardLayout])

    const goBack = (restartAutoPlay: boolean = true) => {
        let newIndex = currentScrollIndex
        if (currentScrollIndex != 0) {
            newIndex--
        } else if (loop) {
            newIndex = items.length - initialItemCount
        }
        if (restartAutoPlay) {
            resetInterval()
        }
        setScrollIndex(newIndex)
    }

    const goForward = (restartAutoPlay: boolean = true) => {
        let newIndex = currentScrollIndex

        const isLastScroll =
            currentScrollIndex === items.length - initialItemCount

        if (!isLastScroll) {
            newIndex = currentScrollIndex + 1
        } else if (loop) {
            newIndex = 0
        }
        if (restartAutoPlay) {
            resetInterval()
        }
        setScrollIndex(newIndex)
    }

    const setScrollIndex = (index: number) => {
        onIndexChange?.(index)
        setCurrentScrollIndex(index)
        flatListRef?.current?.scrollToIndex({ animated: true, index })
    }

    const resetInterval = () => {
        clearInterval(interval.current)
        setRefreshInterval(new Date())
    }

    const getArrowPagination = () => {
        const leftArrowStyle = [
            styles.arrowIconContainer,
            { left: 0 },
            paginationArrowIcons?.arrows[0]?.containerStyle,
        ]
        const rightArrowStyle = [
            styles.arrowIconContainer,
            { right: 0 },
            paginationArrowIcons?.arrows[1]?.containerStyle,
        ]

        const onPressLeftIcon = () => {
            paginationArrowIcons?.arrows[0]?.onPress?.()
            goBack()
            resetInterval()
        }

        const onPressRightIcon = () => {
            goForward()
            resetInterval()
            paginationArrowIcons?.arrows[1]?.onPress?.()
        }

        return (
            <>
                <View style={leftArrowStyle}>
                    <Pressable
                        testID={`${testID}-left-arrow`}
                        onPress={onPressLeftIcon}
                    >
                        {paginationArrowIcons ? (
                            paginationArrowIcons.arrows[0].icon
                        ) : (
                            <Image
                                source={leftArrowIcon}
                                style={styles.arrowIconImage}
                            />
                        )}
                    </Pressable>
                </View>
                <View style={rightArrowStyle}>
                    <Pressable
                        testID={`${testID}-right-arrow`}
                        onPress={onPressRightIcon}
                    >
                        {paginationArrowIcons ? (
                            paginationArrowIcons.arrows[1].icon
                        ) : (
                            <Image
                                source={rightArrowIcon}
                                style={styles.arrowIconImage}
                            />
                        )}
                    </Pressable>
                </View>
            </>
        )
    }

    const getDotPagination = (id: number = 0) => {
        return (
            <DotPagination
                testID={testID + '-' + id}
                count={items.length - initialItemCount + 1} // 1 is added to count to take into account the initial item count
                selectedIndex={currentScrollIndex}
                onSelected={setScrollIndex}
                containerStyle={{
                    marginBottom: 8,
                    marginTop: 8,
                }}
                dotStyle={{ backgroundColor: '#A666FF' }}
                {...paginationDotIcon}
            />
        )
    }

    const getPaginationIcons = () => {
        switch (paginationType) {
            case 'dots':
                return getDotPagination()
            case 'arrows':
                return getArrowPagination()
            case 'both':
                return (
                    <>
                        {getArrowPagination()}
                        {getDotPagination()}
                    </>
                )
            default:
                return <></>
        }
    }

    const isSingleItem = maxItemsPerView === 1

    const flatListWidth =
        maxItemsPerView && !isSingleItem && cardLayout
            ? maxItemsPerView * cardLayout.width
            : '100%'

    return (
        <View
            style={[
                {
                    width: flatListWidth,
                },
                styles.container,
                containerStyle,
            ]}
            testID={testID}
        >
            <FlatList
                // Disable warning for onScrollToIndexFailed on unit test
                // https://stackoverflow.com/a/60320726/15741905
                onScrollToIndexFailed={(info) => {
                    const wait = new Promise((resolve) =>
                        setTimeout(resolve, 500),
                    )
                    wait.then(() => {
                        flatListRef.current?.scrollToIndex({
                            index: info.index,
                            animated: true,
                        })
                    })
                }}
                testID={`${testID}-flat-list`}
                contentContainerStyle={contentContainerStyle}
                ref={flatListRef}
                onLayout={(e) => {
                    setLayout(e.nativeEvent.layout)
                }}
                showsHorizontalScrollIndicator={false}
                data={items}
                keyExtractor={(_, index) => `carousel-${index}`}
                horizontal
                renderItem={({ item, index }) => {
                    if (!layout) {
                        return null
                    }
                    return (
                        <View
                            testID={`${testID}-carousel-item-${index}`}
                            key={`carousel-${index}`}
                            onLayout={(e) => {
                                setCardLayout(e.nativeEvent.layout)
                            }}
                        >
                            {renderItem(item, index, getDotPagination(index))}
                        </View>
                    )
                }}
                {...rest}
            />
            {layout && getPaginationIcons()}
        </View>
    )
}

const styles = StyleSheet.create({
    container: { overflow: 'hidden', flex: 1 },
    arrowIconContainer: {
        position: 'absolute',
        height: 30,
        width: 30,
        backgroundColor: 'white',
        borderRadius: 15,
        top: '50%',
    },
    arrowIconImage: {
        height: 30,
        width: 30,
    },
})

export default Carousel
