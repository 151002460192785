import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { Platform } from "react-native";
import Geolocation from "react-native-geolocation-service";
import MapView from "react-native-maps";

type Filter = "Shop" | "Eat" | "Do" | "Parking";
export type StoreType = {
  id: number;
  email: string;
  activated: boolean;
  user_name: string;
  user_type: string | null;
  status: string;
  role_id: number | null;
  address: string;
  zipcode: string | null;
  operating_hours: string | null;
  closing_time: string | null;
  opening_time: string | null;
  link_to_website: string | null;
  bio: string | null;
  facebook_link: string | null;
  instagram_link: string | null;
  approval_status: string;
  latitude: number;
  longitude: number;
  business_category_type: Array<{
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        name: string;
        dark_icon: string | null;
        dark_icon_active: string | null;
        dark_icon_inactive: string | null;
        light_icon: string;
        light_icon_active: string | null;
        light_icon_inactive: string | null;
        rank: string | null;
        created_at: string;
        updated_at: string;
      }
    };
  }> | null;
  business_sub_category_type: string[];
  phone_number: string | null;
  full_phone_number: string | null;
  photos: string[] | null;
  favorite: boolean;
  profile_image: Array<{ url: string }>;
  business_image: Array<{ url: string }>;
  plan_type: string;
  show_open: string | null;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedSubCategories: string[],
  stores: Array<StoreType>;
  timeoutId: NodeJS.Timeout | null;
  token: string;
  popoverVisibility: boolean;
  businessPreviewVisibility: boolean;
  allowAccess: boolean;
  filtering: Array<Filter>;
  openToday: boolean;
  favourites: boolean;
  mapLimits: {
    south: number;
    north: number;
    west: number;
    east: number;
  } | null,
  cityBoundary: Array<{ latitude: number, longitude: number }>;
  currentPosition: {
    coords: {
      latitude: number | null;
      longitude: number | null;
    },
    city_name: string;
  }
  userLocation: {
    coords: {
      latitude: number | null;
      longitude: number | null;
    },
  }
  geofencePoints: Array<{
    name: string;
    location: {
      lat: number;
      lng: number;
    }
  }>
  selectedStore: StoreType;
  inputSearchText: string;
  sideSection: {
    type: "filters" | "advanceSearch" | "businessPreview" | "storeLocator" | "favourites" | "geofence" | null
    isOpen: boolean;
  }
  showLocateMe: boolean;
  galleryVisibility: boolean;
  galleryPhotos: Array<{ url: string }>;
  currentStoreShowMap: number | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MapsController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      selectedSubCategories: [],
      stores: [],
      timeoutId: null,
      mapLimits: null,
      cityBoundary: [],
      token: "",
      popoverVisibility: false,
      businessPreviewVisibility: false,
      allowAccess: true,
      filtering: [],
      openToday: false,
      favourites: false,
      geofencePoints: [],
      currentPosition: {
        coords: {
          latitude: null,
          longitude: null,
        },
        city_name: "",
      },
      userLocation: {
        coords: {
          latitude: null,
          longitude: null,
        },
      },
      selectedStore: {} as StoreType,
      inputSearchText: "",
      sideSection: {
        type: null,
        isOpen: false,
      },
      showLocateMe: false,
      galleryVisibility: false,
      galleryPhotos: [],
      currentStoreShowMap: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (token) {
        this.setState({
          token
        })
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const location = message.getData(getName(MessageEnum.NavigationCityLocationMessage));

      if (location) {
        const { latitude, longitude, city_name } = location;

        if(this.mapViewRef) {
          this.mapViewRef.current?.animateToRegion({
            latitude: latitude,
            longitude: longitude,
            latitudeDelta: 0.0922,
            longitudeDelta: 0.0421,
          })
        }

        this.setState({ 
          currentPosition: {
            coords: {
              latitude: latitude,
              longitude: longitude
            },
            city_name
          },
        })
        this.getCityBoundary();
        this.getGeofence();
        this.getStores("");
      }
    } 

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken();

    if (!this.state.userLocation.coords.latitude || !this.state.userLocation.coords.longitude) {
      this.setState({
        popoverVisibility: true
      })
    }
  }

  storesApiCallId: string = "";
  geofenceApiCallId: string = "";
  getCityBoundaryCallId: string = "";
  filteringApiCallId: string = "";
  googleMapAPIId: any;
  mapViewRef = React.createRef<MapView>()
  inputSearchRef = React.createRef<HTMLInputElement>()

  private handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.geofenceApiCallId && responseJson && responseJson.data) {
      this.setState({
        geofencePoints: responseJson.data.pois
      })
    }

    if (apiRequestCallId === this.storesApiCallId && responseJson && responseJson.businesses) { 
      this.setState({
        stores: responseJson.businesses.data.map((store: any) => {
          return {
            ...store.attributes
          }
        })
      })
    }

    if (apiRequestCallId === this.getCityBoundaryCallId && responseJson && responseJson[0]) {
      this.setState({
        mapLimits: {
          south: Number(responseJson[0].boundingbox[0]),
          north: Number(responseJson[0].boundingbox[1]) + 0.00850,
          west: Number(responseJson[0].boundingbox[2]),
          east: Number(responseJson[0].boundingbox[3]),
        },
        cityBoundary: this.polygonBoundaryFormat(responseJson)
      })
    }
  }

  polygonBoundaryFormat(responseJson: any) {
    let cityBoundary: Array<{ latitude: number, longitude: number }> = [];
    responseJson = responseJson.filter((response: any) => response?.geojson?.type !== "Point");
    let polygonType = responseJson[0]?.geojson?.type

    if (polygonType === "MultiPolygon") {
      let multipolygonData =  responseJson[0]?.geojson?.coordinates[0][0] as unknown as [number, number][];
      multipolygonData?.map((latLngData) => {
        let multiPolygonBoundary = {
          "latitude": latLngData[1],
          "longitude": latLngData[0]
        }

        cityBoundary.push(multiPolygonBoundary);

        return cityBoundary
      })
    } else {
      let polygonData =  responseJson[0]?.geojson?.coordinates[0] as unknown as [number, number][];

      polygonData?.map((latLngData) => {
        let polygonBoundary = {
          "latitude": latLngData[1],
          "longitude": latLngData[0]
        }

        cityBoundary.push(polygonBoundary);

        return cityBoundary
      })
    }

    return cityBoundary
  }

  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };

  handleToggleFilters = () => {
    this.setState({
      sideSection: {
        type: "filters",
        isOpen: !this.state.sideSection.isOpen,
      },
      currentStoreShowMap: null,
    })
  }

  handleOpenBusinessDetails = (store: StoreType) => {
    this.setState({
      sideSection: {
        type: "storeLocator",
        isOpen: true,
      },
      selectedStore: store,
      currentStoreShowMap: null,
    })
  }

  handleCloseSideSection = (searchText?: string) => {
    const inputSearchText = (searchText !== undefined && typeof searchText === "string") ? searchText : this.state.inputSearchText
    this.setState({
      sideSection: {
        type: null,
        isOpen: false,
      },
      currentStoreShowMap: null,
      inputSearchText,
    })
  }

  handleCloseGallery = () => { this.setState({ galleryVisibility: false })} 

  handleOpenGallery = (photos: Array<{ url: string }>) => {
    this.setState({
      galleryPhotos: photos,
      galleryVisibility: true
    })
  }

  showOnTheMap = async () => {
    this.setState({currentStoreShowMap: this.state.selectedStore.id });

    const camera = {
      center: {
        latitude: this.state.selectedStore.latitude,
        longitude: this.state.selectedStore.longitude,
        lat: this.state.selectedStore.latitude,
        lng: this.state.selectedStore.longitude,
      },
      pitch: 0,
      heading: 0,
      zoom: 16,
    }

    if (this.mapViewRef.current) {
      await this.mapViewRef.current.animateToRegion({
        latitude: this.state.selectedStore.latitude,
        longitude: this.state.selectedStore.longitude,
        latitudeDelta: 0.01,
        longitudeDelta: 0.01
      })

      await this.mapViewRef.current.animateCamera(camera, { duration: 1000 });
    }
  }

  handleFocusInputSearch = () => {
    this.setState({
      sideSection: {
        type: "advanceSearch",
        isOpen: true
      },
      currentStoreShowMap: null,
    })
  }

  handleOpenFavourites = () => {
    this.setState({
      sideSection: {
        type: "favourites",
        isOpen: true,
      },
      currentStoreShowMap: null
    })
  }

  popoverProps = {
    onClose: () => { this.setState({ popoverVisibility: false }) },
    primaryButtonAction: () => { this.getLocation() },
    secondaryButtonAction: () => { this.setState({ popoverVisibility: false }) }
  }

  switchOpenTodayFilterProps = {
    onValueChange: () => this.setState({ openToday: !this.state.openToday }, () => this.getStores(this.state.inputSearchText, this.state.selectedSubCategories))
  }

  switchFavouritesFilterProps = {
    onValueChange: () => { 
      this.setState({ favourites: !this.state.favourites }, () => this.getStores(this.state.inputSearchText, this.state.selectedSubCategories));
    }
  }

  inputSearchProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ inputSearchText: event.target.value })
    }
  }

  getLocation = async () => {
    Geolocation.getCurrentPosition(
      async (position: any) => {
        const { latitude, longitude } = position.coords;
        const locationString = `Latitude: ${latitude}, Longitude: ${longitude}`;

        if (Platform.OS === 'web') {
          navigator.clipboard.writeText(locationString)
        } 
        this.setState({ 
          userLocation: {
            coords: {
              latitude: latitude,
              longitude: longitude
            }
          },
          popoverVisibility: false
        })
      },
      (error: any) => {
        // this.setState({ isSpinnerShowing: false });
      },
      {
        enableHighAccuracy: false,
        timeout: 15000,
        distanceFilter: 0,
        forceRequestLocation: true,
        showLocationDialog: false,
      }
    );
  };
  
  getCityBoundary = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    
    const getCitiessMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCityBoundaryCallId = getCitiessMsg.messageId;

    getCitiessMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCityBoundary}q=${this.state.currentPosition.city_name}&format=json&polygon_geojson=1`
    );

    getCitiessMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getCitiessMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(getCitiessMsg.id, getCitiessMsg);
  }

  getGeofence = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    
    const getCitiessMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.geofenceApiCallId = getCitiessMsg.messageId;

    getCitiessMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGeofenceAPiEndPoint}/${this.state.currentPosition.city_name}`
    );

    getCitiessMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getCitiessMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(getCitiessMsg.id, getCitiessMsg);
  }

  getStores = async (search: string, selectedSubCategories?: string[]) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };

    this.setState({
      inputSearchText: search
    });

    if (!selectedSubCategories) {
      selectedSubCategories = this.state.selectedSubCategories;
    }
    
    const getStoresMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.storesApiCallId = getStoresMsg.messageId;
    // `${configJSON.getStoresApiEndPoint}${this.state.currentPosition.city_name}&open_today=${this.state.openToday}&favourite=${this.state.favourites}&business_name=${search}&bio=${search}&categories=${this.state.filtering.join(',')}&sub_categories=${selectedSubCategories?.join(',') || ""}`
    getStoresMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStoresApiEndPoint}${this.state.currentPosition.city_name}&open_today=${this.state.openToday}&favourite=${this.state.favourites}&search=${search}&categories=${this.state.filtering.join(',')}&sub_categories=${selectedSubCategories.join(',') || ""}`
    );

    getStoresMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getStoresMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(getStoresMsg.id, getStoresMsg);
  }

  handleSaveFilters = (selectedSubCategories: string[]) => {
    this.setState({
      selectedSubCategories: selectedSubCategories,
      filtering: [],
    }, () => {
      this.handleCloseSideSection();
      this.getStores(this.state.inputSearchText, selectedSubCategories);
    })

  }

  onFiltering = (filter: Filter) => {
    this.setState({
      filtering: this.state.filtering.includes(filter) 
        ? this.state.filtering.filter(item => item != filter) 
        : [...this.state.filtering, filter],
      selectedSubCategories: [] 
    }, () => this.getStores(this.state.inputSearchText, [])) 
  }

  handleLocateMe = () => {
    if (this.state.userLocation.coords.latitude === null || this.state.userLocation.coords.longitude === null) {
      this.setState({ popoverVisibility: true })
    } 

    this.setState({
      showLocateMe: !this.state.showLocateMe
    })
  }
  // Customizable Area End
}
