import React, { useState, useEffect, useMemo } from 'react'
import {
    View,
    Pressable,
    FlatList,
    LogBox,
    Image,
    NativeSyntheticEvent,
    NativeTouchEvent,
} from 'react-native'
import { IAccordion, IAccordionItem } from './interface'
import { styles } from './styles'
import { Typography } from '../Typography/Typography'

import ArrowUp from '../../assets/images/image-expand-more-24px-up.png'
import ArrowDown from '../../assets/images/image-expand-more-24px-down.png'

const Accordion: React.FC<IAccordion> = (props) => {
    const {
        data,
        containerStyle,
        contentContainerStyle,
        topArrow,
        bottomArrow,
        titleStyle,
        subTitleStyle,
        itemContainerStyle,
        titleContainerStyle,
        selectedId,
        handleChange,
        testID,
    } = props

    useEffect(() => {
        // ignore warning for nested virtualized lists when the component used inside ScrollView
        LogBox.ignoreLogs(['VirtualizedLists should never be nested'])
    }, [])

    const _topArrow = useMemo(
        () =>
            topArrow === undefined ? (
                <Image source={ArrowUp} style={styles.iconStyle} />
            ) : (
                topArrow
            ),
        [topArrow],
    )

    const _bottomArrow = useMemo(
        () =>
            bottomArrow === undefined ? (
                <Image source={ArrowDown} style={styles.iconStyle} />
            ) : (
                bottomArrow
            ),
        [bottomArrow],
    )

    return (
        <FlatList
            style={[{ overflow: 'visible' }, containerStyle]}
            contentContainerStyle={[
                styles.contentContainer,
                contentContainerStyle,
            ]}
            data={data}
            keyExtractor={(item) => item.id}
            CellRendererComponent={({ children }) => <>{children}</>}
            renderItem={({ item, index }) => {
                return (
                    <RenderAccordionItem
                        item={item}
                        index={index}
                        topArrow={_topArrow}
                        bottomArrow={_bottomArrow}
                        titleStyle={titleStyle}
                        subTitleStyle={subTitleStyle}
                        style={itemContainerStyle}
                        selected={
                            handleChange ? selectedId === item.id : undefined
                        }
                        handleChange={handleChange}
                        titleContainerStyle={titleContainerStyle}
                    />
                )
            }}
            testID={testID}
        />
    )
}

const RenderAccordionItem: React.FC<IAccordionItem> = (props) => {
    const {
        item,
        topArrow,
        bottomArrow,
        style,
        selected,
        titleStyle,
        titleContainerStyle,
        subTitleStyle,
        handleChange,
    } = props

    const [isVisible, setIsVisible] = useState(!!props.selected)

    const RenderAccordionHeader = ({
        item,
        topArrow,
        bottomArrow,
        titleContainerStyle,
        titleStyle,
        subTitleStyle,
        onSelect,
        isVisible,
    }: any) => {
        return (
            <Pressable
                onPress={(e: NativeSyntheticEvent<NativeTouchEvent>) =>
                    onSelect(item.id, e)
                }
                style={[styles.headerContainer, titleContainerStyle]}
            >
                <View style={styles.titleWithIcon}>
                    <Typography style={{ ...titleStyle }}>
                        {item.title}
                    </Typography>
                    {isVisible ? topArrow : bottomArrow}
                </View>
                {item.subTitle ? (
                    <Typography
                        style={{ ...styles.subTitle, ...subTitleStyle }}
                    >
                        {item.subTitle}
                    </Typography>
                ) : null}
            </Pressable>
        )
    }

    const handleSelect = (
        id: string,
        event: NativeSyntheticEvent<NativeTouchEvent>,
    ) => {
        if (selected === undefined) {
            setIsVisible(!isVisible)
        } else {
            // controlled component
            handleChange && handleChange(id, event)
        }
    }

    const isItemSelected = selected === undefined ? isVisible : selected
    const Content = item.content

    return (
        <View style={[styles.itemContainer, style]}>
            <RenderAccordionHeader
                item={item}
                topArrow={topArrow}
                bottomArrow={bottomArrow}
                titleContainerStyle={titleContainerStyle}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                onSelect={handleSelect}
                isVisible={isItemSelected}
            />
            {isItemSelected ? Content : null}
        </View>
    )
}

export { Accordion }
