Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint = "bx_block_custom_user_subs/user_subscriptions";
exports.getBusinessSubscriptions = "bx_block_custom_user_subs/show_business_subscription";
exports.getUserInformationEndPoint = "show_account";
exports.cancelSubscriptionEndPoint = "bx_block_custom_user_subs/user_subscriptions/cancel_subscription";
exports.httpDeleteMethod = "DELETE";

exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";

exports.confirmChangePlanPopoverTitle = "Are you sure you want to change the Subscription ?";
exports.confirmChangePlanPopoverSubTitle = "Currently you are subscribed to <Subscription name> that ends on <Subscription end date>";
exports.confirmChangePlanPopoverPrimaryButtonText = "Yes";
exports.confirmCancelPlanPopoverTitle = "Are you sure you want to cancel your current subscription plan?";
exports.confirmCancelPlanPopoverSubTitle = "You will loose all functionality your plan have";
exports.confirmCancelPlanPopoverPrimaryButtonText = "Cancel Subscription";
exports.confirmChangePlanPopoverPrimaryButtonColor = "#F87171";
exports.confirmChangePlanPopoverSecondaryButtonText = "No";
exports.confirmChangePlanPopoverIconColor = "#8861A9";
// Customizable Area End
