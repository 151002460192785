import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";

type ActivityLog = {
  id: number;
  user_id: number;
  action: string;
  details: string;
  created_at: string;
  updated_at: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  activeLog: Array<ActivityLog>;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ActivityLogController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getActivityLogsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      activeLog: [],
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const isSessionResponseMessage = getName(MessageEnum.SessionResponseMessage) === message.id;
    const isRestAPIResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;

    if (isSessionResponseMessage) {
      this.handleSessionResponseChangePassword(message);
    }

    if (isRestAPIResponseMessage) {
      this.handleAPIResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  private handleSessionResponseChangePassword(message: Message) {
    const sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));

    this.setState({
      token: sessionToken
    }, () => this.getActivityLogs())
  }

  private handleAPIResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (responseJson && !responseJson.error) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId === this.getActivityLogsCallId) {
        this.setState({
          activeLog: responseJson.activity,
          isLoading: false
        })
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();

    const isToday = 
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() && 
      date.getFullYear() === now.getFullYear()

    if (isToday) {
      const hours = date.getHours()
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHour = hours % 12 || 12;
      return `${formattedHour} ${ampm}`;
    } else {
      const diffInTime = now.getTime() - date.getTime();
      const daysAgo = Math.floor(diffInTime / (1000 * 60 * 60 * 24))

      return daysAgo <= 1 ? '1 day ago' : `${daysAgo} days ago`;
    }
  }

  btnGoBackProps = {
    onPress: () => this.props.navigation.goBack()
  };

  getActivityLogs = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getActivityLogsCallId = requestMessage.messageId;

    this.setState({
      isLoading: true
    })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getActivictyLogsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
